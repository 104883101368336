"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var component_1 = require("@taikonauten/component");
var ContactForm = /** @class */ (function (_super) {
    __extends(ContactForm, _super);
    function ContactForm(el) {
        var _this = _super.call(this, el) || this;
        _this.form = null;
        _this.onSubmit = function (e) { return __awaiter(_this, void 0, Promise, function () {
            var button, data, action, response, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        button = this.el.querySelector('.cta');
                        button === null || button === void 0 ? void 0 : button.classList.add('cta--loading');
                        if (!this.form) {
                            return [2 /*return*/];
                        }
                        data = Object.values(this.form).reduce(function (obj, field) {
                            obj[field.name] = field.value;
                            return obj;
                        }, {});
                        action = "".concat(window.location.origin, "/").concat(this.form.getAttribute('data-api'));
                        return [4 /*yield*/, fetch(action, {
                                method: 'POST',
                                mode: 'cors',
                                cache: 'no-cache',
                                credentials: 'same-origin',
                                headers: {
                                    'X-CSRF': this.form.querySelector('[name="csrf"]').value,
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                },
                                redirect: 'follow',
                                referrerPolicy: 'no-referrer',
                                body: new URLSearchParams(data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        json = _a.sent();
                        if (json.success) {
                            this.el.classList.add('contact-form--success');
                        }
                        button === null || button === void 0 ? void 0 : button.classList.remove('cta--loading');
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ContactForm.prototype.init = function () {
        this.form = this.el.querySelector('form');
        if (this.form) {
            var formElements = Array.from(this.form.elements);
            formElements.forEach(function (el) {
                el.addEventListener('input', function () {
                    el.classList.add('input--dirty');
                });
                el.addEventListener('change', function () {
                    el.classList.add('input--dirty');
                });
                el.addEventListener('blur', function () {
                    el.classList.add('input--dirty');
                });
            });
            this.populateSubject();
            this.form.addEventListener('submit', this.onSubmit);
        }
    };
    ContactForm.prototype.destroy = function () {
        var _a;
        (_a = this.form) === null || _a === void 0 ? void 0 : _a.removeEventListener('submit', this.onSubmit);
    };
    ContactForm.prototype.populateSubject = function () {
        if (this.form) {
            var urlParams = new URL(window.location.href).searchParams;
            var formSelect_1 = this.form.querySelector('#form-subject');
            urlParams.forEach(function (value) {
                var _a;
                (_a = formSelect_1.querySelector("option[value=\"".concat(value, "\"]"))) === null || _a === void 0 ? void 0 : _a.setAttribute('selected', 'selected');
            });
        }
    };
    return ContactForm;
}(component_1.Component));
exports.default = ContactForm;
